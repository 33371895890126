import { DisabledByDefault, Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";

const GenericForm = (props) => {
  const inputRef = useRef(null);
  const {
    formFields,
    handleSubmit,
    renderCustomInput,
    image,
    setImage,
    loading,
    setIsDrawerOpen,
    onError,
    errors,
    value,
    setValue,
    setDownloadType
  } = props;
  const user = JSON.parse(sessionStorage.getItem("_secure_user_details"));
  const role = user.roles[0];


  const handleEditClick = () => {
    inputRef.current.click();
  };

  function getBase64(file) {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file && file.size < 1024 * 1024) {
      const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!acceptedImageTypes.includes(file.type)) {
        props?.onError({ response: { data: 'Please upload a valid image file (jpg, png, gif)' } });
        event.target.value = '';
        return;
      }
      getBase64(file)
        .then((base64Version) => {
          setImage(base64Version);
        })
        .catch((err) => {
          console.error("An error occured during base64 conversion.", err);
        });
    } else {
      const error = {
        message: "File size exceeds the 1MB limit",
      };
      onError(error);
    }
  };
  const handleClose = () => {
    setIsDrawerOpen(false);
  };
  let dropdownItems = []
  const chooseReportDropdownItems = [
    { name: "Project Report", value: "project" },
    { name: "OverTime Analysis Report", value: "overtime" },
  ];
  const adminChooseReportDropdownItems = [
    ...chooseReportDropdownItems,
    { name: "Overall Report", value: "all" },
  ];
  if (role === "Admin") {
    dropdownItems.push(adminChooseReportDropdownItems);
  } else {
    dropdownItems.push(chooseReportDropdownItems);
  }
  return (
    <Container maxWidth="lg" component={"main"}>
      <IconButton
        style={{
          position: "absolute",
          top: "0",
          right: "0",
          fontSize: "large",
        }}
        onClick={handleClose}
      >
        <DisabledByDefault />
      </IconButton>
      {props.type === "Tasks" || props.type === "Report" ? (
        <React.Fragment></React.Fragment>
      ) : (
        <Box>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box style={{ position: "relative" }}>
              <Avatar
                alt="image"
                src={image}
                sx={{ width: 100, height: 100 }}
              />
              <Box style={{ position: "absolute", bottom: 0, width: "100%" }}>
                <Stack direction="row" spacing={2} justifyContent="center">
                  <Tooltip title="Edit Image">
                    <IconButton
                      sx={{
                        color: "#fff",
                        backgroundColor: "#555",
                        fontSize: "small",
                        opacity: 0.8,
                      }}
                      onClick={handleEditClick}
                    >
                      <Edit />
                      <input
                        type="file"
                        id="profile-image-input"
                        hidden
                        ref={inputRef}
                        onChange={handleFileChange}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Box>
      )}
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {props.type === "Report" ? (
            <Grid item xs={12}>
              <TextField
                select
                size="small"
                InputLabelProps={{
                  style: { font: "small-caption", fontFamily: "Lucida Grande" },
                  shrink: true,
                }}
                InputProps={{ style: { font: "small-caption", fontFamily: "Lucida Grande" } }}
                label="Choose Report"
                placeholder="Choose Report"
                fullWidth
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              >
                {dropdownItems[0].map((val) => (
                  <MenuItem sx={{ fontFamily: "Lucida Grande" }} key={val} value={val.value}>
                    {val.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ) : (
            <></>
          )}
          {value && value === "all" ? (
            formFields
              .filter((item) => item.id !== "project")
              .map((field, key) => (
                <Grid item xs={12} key={key}>
                  {renderCustomInput(field)}
                </Grid>
              ))
          ) : (
            <>
              {formFields.map((field, key) => (
                <Grid item xs={12} key={key}>
                  {renderCustomInput(field)}
                </Grid>
              ))}
            </>
          )}

          <Grid
            item
            xs={12}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {!loading ? (
              <>
              {props.type === "Report" ? (
                <>
                  <Tooltip title={Object.keys(errors).length !== 0 ? "Button is Disabled" : null}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={()=>setDownloadType("PDF")}
                      disabled={Object.keys(errors).length !== 0}
                    >
                      <Typography sx={{ color: "white", fontFamily: "Lucida Grande", fontSize: "12px" }}>
                        Generate PDF
                      </Typography>
                    </Button>
                  </Tooltip>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {value !== "all" && (
                  <Tooltip title={Object.keys(errors).length !== 0 ? "Button is Disabled" : null}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={()=>setDownloadType("")}
                      disabled={Object.keys(errors).length !== 0}
                    >
                      <Typography sx={{ color: "white", fontFamily: "Lucida Grande", fontSize: "12px" }}>
                        Generate Excel
                      </Typography>
                    </Button>
                  </Tooltip>
                  )}
                </>
              ) : (
                <Tooltip title={Object.keys(errors).length !== 0 ? "Button is Disabled" : null}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={Object.keys(errors).length !== 0}
                  >
                    <Typography sx={{ color: "white", fontFamily: "Lucida Grande", fontSize: "12px" }}>
                      Save
                    </Typography>
                  </Button>
                </Tooltip>
              )}
            </>
          
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default GenericForm;
