import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";
import { PROJECTS_DETAILS_PATH, PROJECTS_PATH } from "../constants/Constants";
// import ProjectsComponent from "../pages/Projects";
// import ProjectsDetailsComponent from "../pages/ProjectDetails";

const ProjectsComponent = React.lazy(() => import("../pages/Projects"));
const ProjectsDetailsComponent = React.lazy(() => import("../pages/ProjectDetails"));


export const PROJECT_ROUTES = [
    <Route path={PROJECTS_PATH} element={
        <PrivateRoute>
            <Layout component={ProjectsComponent} path={PROJECTS_PATH} title="Projects"></Layout>
        </PrivateRoute>}
        key={"PROJECT_ROUTE"}>
    </Route>,
    <Route path={PROJECTS_DETAILS_PATH} element={
        <PrivateRoute>
            <Layout component={ProjectsDetailsComponent} path={PROJECTS_DETAILS_PATH} title="Project Details"></Layout>
        </PrivateRoute>}
        key={"PROJECT_DETAILS_ROUTE"}>
    </Route>
]
