import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";
import { HOLIDAY_PATH } from "../constants/Constants";
import Holiday from '../pages/Holiday';
//const HolidayComponent = React.lazy(() => import('../pages/Holiday'));

export const HOLIDAY_ROUTES = [

    <Route path={HOLIDAY_PATH} element={
        <PrivateRoute>
            <Layout component={Holiday}  path={HOLIDAY_PATH} title="Holiday"></Layout>
        </PrivateRoute>
    } key={"HOLIDAY_ROUTES"}></Route>
];
