import {
  AUTH_EMAIL_REGUALR_EXPRESSION,
  AUTH_PASSWORD_REGULAR_EXPRESSION,
  AUTH_PERSONALEMAIL_REGUALR_EXPRESSION,
} from "../../edvenswa.emportal.auth/constants/Constants";

export function isValidEmail(email) {
  if (new RegExp(AUTH_EMAIL_REGUALR_EXPRESSION).test(email)) {
    return true;
  }
  return false;
}
export function isValidPersonalEmail(email) {
  if (new RegExp(AUTH_PERSONALEMAIL_REGUALR_EXPRESSION).test(email)) {
    return true;
  }
  return false;
}

export function isValidPassword(password) {
  if (new RegExp(AUTH_PASSWORD_REGULAR_EXPRESSION).test(password)) {
    return true;
  }
  return false;
}

export function isValidOTP(otp) {
  // add validation - OTP should be 6 digits
  if (otp.length !== 6) {
    return false;
  }
  return true;
}

export function comparePasswords(password, confirmPassword) {
  // add validation - Entered password and confirm password should be same.
  if (password !== confirmPassword) {
    return true;
  }
  return false;
}
export function isValidTitle(title) {
  // const regex = /^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*)?$/;
  const regex = /^(?=[A-Z0-9])[^\s]{2}(\s{0,2}[^\s]+)*$/;
  if (title.length < 2 || !regex.test(title)) {
    return false;
  }
  return true;
}
export function isValidTask(title) {
  const regex = /^(?=[A-Z0-9])/;
  if (title.length < 2 || !regex.test(title)) {
    return false;
  }
  return true;
}

export function isValidDescription(desc) {
  const trimmedDesc = desc.trim();  // Trim spaces from both ends
  const regex = /^(?=[A-Z0-9])/;
  if (trimmedDesc.length < 10 || !regex.test(trimmedDesc)) {
    return false;
  }
  return true;
}


export function isValidDuration(duration) {
  if (duration <= 10) {
    return false;
  }
  return true;
}
export function isValidWorkingHours(duration) {
  if (duration > 40) {
    return false;
  }
  return true;
}
export function isValidWorkedHours(duration) {
  if (duration > 8) {
    return false;
  }
  return true;
}
export function isValidDate(value) {
  let date = new Date(value);
  let currentYear = new Date().getFullYear();
  return date.getFullYear() === currentYear;
}

export function isValidString(firstname) {
  if (new RegExp(/^[a-zA-Z]+(?:[ \t]+[a-zA-Z]+)*$/).test(firstname)) {
    return true;
  }
  return false;
}

export function isValidMobileNo(mobileNo) {
  if (new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/).test(mobileNo)) {
    return true;
  }
  return false;
}
export function isValidNumber(input) {
  if (new RegExp(/^[0-9]+$/).test(input)) {
    return true;
  }
  return false;
}
export function isValidFirstAndLastName(title) {
  const regex = /^(?=[A-Z])(?!.* {2,})[^\s].{1,}[^\s]$/;
  if (title.length < 3 || !regex.test(title)) {
    
    return false;
  }
  return true;
}
// export function isValidPersonalEmail(title) {
//   const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
//   if (!regex.test(title)) {
//     return false;
//   }
//   return true;
// }
export function isValidKey(key) {
  const regex = /^[A-Z0-9]{3,}$/
  if (key.length < 3 || !regex.test(key)) {
    return false;
  }
  return true;
}