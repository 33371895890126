export const PROJECT_TITLE_FIELD_ID = "title";
export const PROJECT_KEY_FIELD_ID = "customerName";
export const PROJECT_DESCRIPTION_FIELD_ID = "description";
export const PROJECT_RESOURCES_FIELD_ID = "resources";
export const PROJECT_STATUS_FIELD_ID = "status";
export const PROJECT_RESOURCES_COUNT_FIELD_ID = "resourcesCount";
export const PROJECT_START_DATE_FIELD_ID = "startDate";
export const PROJECT_END_DATE_FIELD_ID = "endDate";
export const PROJECT_CATEGORY_FIELD_ID = "projectCategory";
export const PROJECT_COMPANY_FIELD_ID = "tenant";
export const PROJECT_ESTIMATED_HOURS_FIELD_ID = "estimatedHours";
export const PROJECT_MANAGER_FIELD_ID = "projectManager";
export const PROJECT_VALUE_FIELD_ID = "targetMargin";
export const PROJECT_IMAGE_FIELD_ID = "logo";
export const PROJECT_CREATED_BY_FIELD_ID = "createdBy";
export const PROJECT_CREATED_ON_FIELD_ID = "createdOn";
export const PROJECT_DEFAULT_IMAGE =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWquULhlDHRQH4XTv-oa1NCCqcw-WUMN1FzicCvvum3BQ6PWVW";

export const PROJECTS_PATH = "/home/projects";
export const PROJECTS_DETAILS_PATH = "/home/projects/details";
export const PROJECT_CATEGORY_MAPPING = "/project/category";
export const DIALOG_TYPE = "Project Category";
export const DIALOG_FIELD_NAME = "category"
