import React, { useState } from 'react'
import ReportComponent from '../../edvenswa.emportal.reports/pages/ReportComponent'
import jsPDF from 'jspdf';
import { axiosInstance } from '../../../interceptors/AxiosInterceptor';
import moment from 'moment';
import { utils, write } from 'xlsx';
import saveAs from "file-saver";

const AdminScreenAppraisal = (props) => {

    const { onError, setLoad, user } = props;
    const [tenantId, setTenantId] = useState(user.tenantId);
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [appType, setAppType] = useState("Users");
    const [filteredElements, setFilteredElements] = useState([]);
    const [datefilter, setDateFilter] = useState("Last Bi-Weekly");
    // const [startDate, setStartDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').add(15, 'day').format('YYYY-MM-DD') : moment(new Date()).startOf('month').format('YYYY-MM-DD'));
    // const [endDate, setEndDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).endOf('month').format('YYYY-MM-DD') : moment(new Date()).startOf('month').add(14, 'days').format('YYYY-MM-DD'));


    const [startDate, setStartDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').format('YYYY-MM-DD') : moment(new Date()).subtract(1, 'month').startOf('month').add(15, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').add(14, 'days').format('YYYY-MM-DD') : moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));

    const [sortBy, setSortBy] = useState("ACTIVE");

    // startdate should be start of month or 16 th of the month compulsary
    //enddate should be 15th or end of month
    let arr = [];
    if ((moment(startDate).format('DD') === '01' || moment(startDate).format('DD') === '16') &&
        (moment(endDate).format('DD') === '15' || moment(endDate).format('DD') === moment(endDate).daysInMonth().toString())) {
        let count = 1
        for (
            let i = moment(startDate);
            moment(i).isSameOrBefore(endDate);
            i = moment(i).add(1, "day")
        ) {
            if (moment(i).format('DD') === '01' || moment(i).format('DD') === '15' || moment(i).format('DD') === '16' || moment(i).format('DD') === moment(i).daysInMonth().toString()) {
                count++;
                if (count % 2 == 0) {
                    arr.push(moment(i).format("DD/MMM"))
                }
                else {
                    arr.push(moment(i).format("DD/MMM/YYYY"))
                }
            }
        }
    }
    else {
        console.log('select dates correctly... startdate should start of month and enddate should be end of month ')
    }

    const handleDownloadReportExcel = () => {
        if (appType === "Users") {
            axiosInstance
                .post(
                    `/user/getAllassociatesforRatings?sortBy=${sortBy}&tenantId=${tenantId}`,
                    { startDate, endDate }
                )
                .then((res) => {
                    const filteredElements = res.data;
                    const fileName = `All Associates Ratings Report.xlsx`;
                    if (filteredElements.length > 0) {
                        const formattedData = filteredElements.map((row) => {
                            return {
                                "Ast ID": row?.associateId ? row?.associateId : "",
                                "FullName": row.firstname && row.lastname ? `${row.firstname} ${row.lastname}` : "",
                                "Projects": row.projectTitles,
                                "Comments": '',
                                "Ratings": row.ratings ? row.ratings.toFixed(2) : 0.00,
                                "userAppraislsList": row.userAppraisalsList ? row.userAppraisalsList : ''
                            };
                        });
                        let userArr = [];
                        let newObj = {}
                        for (let i = 0; i < arr.length; i++) {
                            if (i % 2 == 0) {
                                newObj = { ...newObj, [arr[i].concat(' - ').concat(arr[i + 1])]: 0 }
                            }
                        }
                        formattedData.map((data) => {
                            let userObj = {};
                            let comment = ''
                            if (data["userAppraislsList"].length !== 0) {
                                let changedNewObj = { ...newObj };
                                data["userAppraislsList"].map((appraisal) => {
                                    comment = appraisal.comments
                                    Object.keys(newObj).map((key) => {
                                        if (key === moment(appraisal.startDate, 'YYYY,MM,DD').format("DD/MMM").concat(' - ').concat(moment(appraisal.endDate, 'YYYY,MM,DD').format("DD/MMM/YYYY"))) {
                                            changedNewObj[key] = appraisal.ratings.toFixed(2)
                                        }
                                    })
                                })
                                userObj = { ...data, ...changedNewObj, Comments: comment }
                            } else {
                                userObj = { ...data, ...newObj }
                            }
                            userArr.push(userObj);
                        })

                        const header = [
                            "Ast ID",
                            "FullName",
                            "Projects",
                            "Comments",
                            "Ratings",
                        ];
                        const newHeader = header.concat(Object.keys(newObj));

                        const userData = userArr.map((val) => newHeader.map((header) => val[header]))
                        // Create the worksheet and workbook
                        const worksheet = utils.aoa_to_sheet([newHeader, ...userData]);
                        const workbook = {
                            Sheets: { data: worksheet },
                            SheetNames: ["data"],
                        };

                        // Convert workbook to excel buffer
                        const excelBuffer = write(workbook, {
                            bookType: "xlsx",
                            type: "array",
                        });

                        // Create a Blob and trigger download
                        const fileType =
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                        const data = new Blob([excelBuffer], { type: fileType });
                        saveAs(data, fileName);
                    }
                })
                .catch((err) => onError(err));
        }
        else {
            const fileName = `Project_Managers_Not_Submitted_Ratings.xlsx`;
            const formattedData = filteredElements.map((row) => {
                return {
                    "Ast ID": row?.associateId ? row?.associateId : "",
                    "FullName": row.firstname && row.lastname ? `${row.firstname} ${row.lastname}` : "",
                    "Projects": row.projectTitles,
                };
            });

            const header1 = [
                { label: "Ast ID", key: "Ast ID" },
                { label: "FullName", key: "FullName" },
                { label: "Projects", key: "Projects" },
            ];

            const worksheet = utils.json_to_sheet(formattedData, {
                header: header1.map((h) => h.label),
            });
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ["data"],
            };
            const excelBuffer = write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            const fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const data = new Blob([excelBuffer], { type: fileType });
            saveAs(data, fileName);
        }
    };


    const handleDownloadReport = () => {
        if (appType === "Users") {
            axiosInstance.post(`/user/getAllassociatesforRatings?sortBy=${sortBy}&tenantId=${tenantId}`,
                { startDate, endDate }).then(res => {
                    const filteredElements = res.data;
                    if (filteredElements.length > 0) {
                        const pdfWidth = 210;
                        const pdfHeight = 250;
                        const pdf = new jsPDF({
                            orientation: "landscape",
                            unit: "mm",
                            format: [pdfWidth, pdfHeight],
                        });
                        const logoUrl = 'https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180';
                        const centerX = pdf.internal.pageSize.width / 2;
                        const imageWidth = 20;
                        const imageHeight = 20;
                        const imageY = 2;
                        pdf.addImage(logoUrl, centerX - imageWidth / 2, imageY, imageWidth, imageHeight);

                        const formattedData = filteredElements.map((row) => {
                            return {
                                "Ast ID": row?.associateId ? row?.associateId : "",
                                "FullName": row?.firstname && row?.lastname ? `${row?.firstname} ${row?.lastname}` : "",
                                "Projects": row?.projectTitles ? row.projectTitles : "",
                                "Avg. Ratings": row?.ratings.toFixed(2),
                                // "userAppraisalsList": row?.userAppraisalsList?.length > 0
                                //     ? row?.userAppraisalsList.map((data) => {
                                //         return `StartDate: ${moment(data.startDate, 'YYYY,MM,DD').format("DD-MMM-YYYY")}, EndDate: ${moment(data.endDate, 'YYYY,MM,DD').format("DD-MMM-YYYY")}, Rating: ${data.ratingAvg}`;
                                //     }).join("\n\n")
                                //     : `StartDate: ${moment(startDate).format("DD-MMM-YYYY")}, EndDate: ${moment(endDate).format("DD-MMM-YYYY")}, Rating: 0`
                            };
                        });
                        const header = [
                            "Ast ID",
                            "FullName",
                            "Projects",
                            "Avg. Ratings",
                            // "User Appraisals"
                        ];

                        // const userData = formattedData.map((val) => header.map((header) => header !== "userAppraisalsList" ? val[header] : val[header]?.map((data) => Object.keys(data).map(key => data[key]))))
                        const userData = formattedData.map((val) => {
                            return [
                                val["Ast ID"],
                                val["FullName"],
                                val["Projects"],
                                val["Avg. Ratings"],
                                // val["userAppraisalsList"] // already formatted as a string
                            ];
                        });

                        const tableStartY = imageY + imageHeight + 20; //44

                        pdf.autoTable({
                            head: [header],
                            body: userData,
                            margin: { left: 8, right: 8, bottom: 10 },
                            startY: startDate && endDate ? 58 : 58,
                            rowPageBreak: 'avoid',
                            headStyles: { fillColor: "#008080", textColor: "#ffffff", fontStyle: "bold" },

                            didDrawPage: function (data) {
                                if (data.pageNumber === 1) {
                                    pdf.setFont("Times New Roman, Times, serif");
                                    pdf.text(`Dear Admin, Following is the report of All Users.`, 12, tableStartY - 6);
                                    pdf.setFontSize(13);
                                    pdf.text(`For the time period of ${moment(startDate).format('DD-MMM-yyyy')} to ${moment(endDate).format('DD-MMM-yyyy')} `, 12, 46);
                                    // pdf.text((`Total Working Hours- ${Math.floor(totalDates.length * 8)}:00, Total Hours Logged- ${totalLoggedHours}, Total Leave Hours- ${totalLeaveHours},  Avg. Utilization- ${formattedPercentage}`), 12, startDate && endDate ? 54 : 46);
                                }
                            },
                            didParseCell: function (data) {
                                data.cell.styles.lineWidth = 0.1;
                                data.cell.styles.lineColor = [0, 0, 0];
                            },
                        });

                        pdf.save(`AllAssociateRatings.pdf`);
                    }

                }).catch(err => onError(err));
        }
        else {
            if (filteredElements.length > 0) {
                const pdfWidth = 210;
                const pdfHeight = 250;
                const pdf = new jsPDF({
                    orientation: "landscape",
                    unit: "mm",
                    format: [pdfWidth, pdfHeight],
                });
                const logoUrl = 'https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180';
                const centerX = pdf.internal.pageSize.width / 2;
                const imageWidth = 20;
                const imageHeight = 20;
                const imageY = 2;
                pdf.addImage(logoUrl, centerX - imageWidth / 2, imageY, imageWidth, imageHeight);

                const formattedData = filteredElements.map((row) => {
                    return {
                        "Ast ID": row?.associateId ? row?.associateId : "",
                        "FullName": row?.firstname && row?.lastname ? `${row?.firstname} ${row?.lastname}` : "",
                        "Projects": row?.projectTitles ? row.projectTitles : "",
                        // "Avg. Ratings": row?.ratings.toFixed(2),
                        // "userAppraisalsList": row?.userAppraisalsList?.length > 0
                        //     ? row?.userAppraisalsList.map((data) => {
                        //         return `StartDate: ${moment(data.startDate, 'YYYY,MM,DD').format("DD-MMM-YYYY")}, EndDate: ${moment(data.endDate, 'YYYY,MM,DD').format("DD-MMM-YYYY")}, Rating: ${data.ratingAvg}`;
                        //     }).join("\n\n")
                        //     : `StartDate: ${moment(startDate).format("DD-MMM-YYYY")}, EndDate: ${moment(endDate).format("DD-MMM-YYYY")}, Rating: 0`
                    };
                });
                const header = [
                    "Ast ID",
                    "FullName",
                    "Projects",
                    // "Avg. Ratings",
                    // "User Appraisals"
                ];

                // const userData = formattedData.map((val) => header.map((header) => header !== "userAppraisalsList" ? val[header] : val[header]?.map((data) => Object.keys(data).map(key => data[key]))))
                const userData = formattedData.map((val) => {
                    return [
                        val["Ast ID"],
                        val["FullName"],
                        val["Projects"],
                        // val["Avg. Ratings"],
                        // val["userAppraisalsList"] // already formatted as a string
                    ];
                });

                const tableStartY = imageY + imageHeight + 20; //44

                pdf.autoTable({
                    head: [header],
                    body: userData,
                    margin: { left: 8, right: 8, bottom: 10 },
                    startY: startDate && endDate ? 58 : 58,
                    rowPageBreak: 'avoid',
                    headStyles: { fillColor: "#008080", textColor: "#ffffff", fontStyle: "bold" },

                    didDrawPage: function (data) {
                        if (data.pageNumber === 1) {
                            pdf.setFont("Times New Roman, Times, serif");
                            pdf.text(`Dear Admin, Following is the report of Project Managers not submitted Ratings.`, 12, tableStartY - 6);
                            pdf.setFontSize(13);
                            pdf.text(`For the time period of ${moment(startDate).format('DD-MMM-yyyy')} to ${moment(endDate).format('DD-MMM-yyyy')} `, 12, 46);
                            // pdf.text((`Total Working Hours- ${Math.floor(totalDates.length * 8)}:00, Total Hours Logged- ${totalLoggedHours}, Total Leave Hours- ${totalLeaveHours},  Avg. Utilization- ${formattedPercentage}`), 12, startDate && endDate ? 54 : 46);
                        }
                    },
                    didParseCell: function (data) {
                        data.cell.styles.lineWidth = 0.1;
                        data.cell.styles.lineColor = [0, 0, 0];
                    },
                });

                pdf.save(`Project_Managers_Not_Submitted_Ratings.pdf`);
            }

        }

    }



    return (
        <div>
            <ReportComponent {...props}
                type={"Appraisals"}
                handleDownloadReport={handleDownloadReport}
                handleDownloadReportExcel={handleDownloadReportExcel}
                setStartDate={setStartDate}
                startDate={startDate}
                endDate={endDate}
                setDateFilter={setDateFilter}
                setEndDate={setEndDate}
                startDateError={startDateError}
                endDateError={endDateError}
                setStartDateError={setStartDateError}
                setEndDateError={setEndDateError}
                sortBy={sortBy}
                appType={appType}
                setFilteredElements={setFilteredElements}
                setAppType={setAppType}
                setSortBy={setSortBy}
                displayAdd={false}
            />
        </div>
    )
}

export default AdminScreenAppraisal
