export const COMPANY_NAME_FIELD_ID = "name";
export const COMPANY_DESCRIPTION_FIELD_ID = 'description';
export const COMPANY_WEBSITE_FIELD_ID = 'website';
export const COMPANY_LOCATION_FIELD_ID = "location";
export const COMPANY_PHONE_FIELD_ID = 'phone';
export const COMPANY_IMAGE_FIELD_ID = "logo";
export const COMPANY_STATUS_FIELD_ID = "status";
export const COMPANY_DEFAULT_IMAGE = "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcRSa15yj2TCzYPa8zoWCsWfa-Nwc71-49o1b28gQUO1dCXitqCS";

export const COMPANY_PATH="/home/company";
export const COMPANY_DETAILS_PATH="/home/company/details";