import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";
import { ASSOCIATE_DEATILS_PATH, ASSOCIATE_PATH } from "../constants/Constants";
// import UsersComponent from "../user/Users";

const UsersComponent = React.lazy(() => import("../user/Users"));
const UserDetailsComponent = React.lazy(() => import("../user/UserDetails"));

export const CONSOLE_ROUTES = [
  <Route
    path={ASSOCIATE_PATH}
    element={
      <PrivateRoute>
        <Layout
          component={UsersComponent}
          path={ASSOCIATE_PATH}
          title="Users"
        ></Layout>
      </PrivateRoute>
    }
    key={"USER_ROUTE"}
  ></Route>,
  <Route
    path={ASSOCIATE_DEATILS_PATH}
    element={
      <PrivateRoute>
        <Layout
          component={UserDetailsComponent}
          path={ASSOCIATE_DEATILS_PATH}
          title="User Details"
        ></Layout>
      </PrivateRoute>
    }
    key={"USER_DETAILS_ROUTE"}
  ></Route>,
];
