import { ROLES, ROLE_BASED_RESOURCES } from "../constants/constants";
import SidebarItems from "../SidebarData";
export const getResources = (USER_ROLES) => {
    let RESULT = [];
    if (Array.isArray(USER_ROLES)) {
        USER_ROLES.forEach((ROLE_NAME) => {
            // Check if it is a valid role
            const IS_VALID_ROLE = ROLES.find(ROLE => (ROLE === ROLE_NAME));
            if (IS_VALID_ROLE) {
                // get the resources by role name
                const RESOURCES = ROLE_BASED_RESOURCES[ROLE_NAME];
                SidebarItems.forEach((ITEM) => {
                    if (RESOURCES.find((RESOURCE) => (RESOURCE === (ITEM.id)))) {
                        // avoid duplicates in the result set
                        const IS_ITEM_EXISTS = RESULT.find(RES => (RES.id === ITEM.id));
                        if (!IS_ITEM_EXISTS) {
                            RESULT.push(ITEM);
                        }
                    }
                });
            } else {
                throw new Error(ROLE_NAME + " DOES NOT EXISTS.");
            }
        });
    } else {
        throw new Error(USER_ROLES + " IS NOT AN ARRAY");
    }
    return RESULT;
};