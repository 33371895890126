import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
import { AUTH_SECURE_USER_DETAILS_LS_LEY } from "../../edvenswa.emportal.auth/constants/Constants";

//create action
export const createTask = createAsyncThunk(
  "createUser",
  async (data, { rejectWithValue }) => {
    console.log("data", data);
    const response = await fetch(
      "http://localhost:8080/emportal/task/create",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    try {
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

//read action
export const showTask = createAsyncThunk(
  "showTask",
  async (data, { rejectWithValue }) => {
    const response = await fetch(
      "http://localhost:8080/emportal/task/getAllByUser", {
      method: 'PUT',
      headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${JSON.parse(sessionStorage.getItem(AUTH_SECURE_USER_DETAILS_LS_LEY)).token}`,
        "Accept": 'application/json'
      },
      body: JSON.stringify(data),
    }
    );

    try {
      const result = await response.json();
      console.log(result);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


//delete action
export const deleteTask = createAsyncThunk(
  "deleteUser",
  async (id, { rejectWithValue }) => {
    const response = await fetch(
      `https://641dd63d945125fff3d75742.mockapi.io/crud/${id}`,
      { method: "DELETE" }
    );

    try {
      const result = await response.json();
      console.log(result);
      return result;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

//update action
export const updateTask = createAsyncThunk(
  "updateUser",
  async (data, { rejectWithValue }) => {
    console.log("updated data", data);
    const response = await fetch(
      `https://641dd63d945125fff3d75742.mockapi.io/crud/${data.id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    try {
      const result = await response.json();

      return result;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const taskDetail = createSlice({
  name: "taskDetail",
  initialState: {
    databyName: {},
    tasks: [],
    loading: false,
    error: null,
    searchData: [],
  },

  reducers: {
    searchUser: (state, action) => {
      console.log(action.payload);
      state.searchData = action.payload;
    },
    // showTask: (state, action) => {
    //   state.tasks = action.payload;
    // }
  },

  // extraReducers: {
  //   [createUser.pending]: (state) => {
  //     state.loading = true;
  //   },
  //   [createUser.fulfilled]: (state, action) => {
  //     state.loading = false;
  //     state.users.push(action.payload);
  //   },
  //   [createUser.rejected]: (state, action) => {
  //     state.loading = false;
  //     state.error = action.payload.message;
  //   },
  //   [showUser.pending]: (state) => {
  //     state.loading = true;
  //   },
  //   [showUser.fulfilled]: (state, action) => {
  //     state.loading = false;
  //     state.users = action.payload;
  //   },
  //   [showUser.rejected]: (state, action) => {
  //     state.loading = false;
  //     state.error = action.payload;
  //   },

  //   [deleteUser.pending]: (state) => {
  //     state.loading = true;
  //   },
  //   [deleteUser.fulfilled]: (state, action) => {
  //     state.loading = false;
  //     const { id } = action.payload;
  //     if (id) {
  //       state.users = state.users.filter((ele) => ele.id !== id);
  //     }
  //   },
  //   [deleteUser.rejected]: (state, action) => {
  //     state.loading = false;
  //     state.error = action.payload;
  //   },

  //   [updateUser.pending]: (state) => {
  //     state.loading = true;
  //   },
  //   [updateUser.fulfilled]: (state, action) => {
  //     state.loading = false;
  //     state.users = state.users.map((ele) =>
  //       ele.id === action.payload.id ? action.payload : ele
  //     );
  //   },
  //   [updateUser.rejected]: (state, action) => {
  //     state.loading = false;
  //     state.error = action.payload.message;
  //   },
  // },
  extraReducers: (builder) => {
    builder.addCase(createTask.pending, (state, action) => {
      state.loading = false
    })
    builder.addCase(showTask.pending, (state, action) => {
      state.loading = false
    })
    builder.addCase(showTask.fulfilled, (state, action) => {
      state.loading = false;
      state.tasks = action.payload;
    })
    builder.addCase(showTask.rejected, (state, action) => {
      state.loading = false
    })
  }
});

export default taskDetail;

export const { searchUser } = taskDetail.actions;
