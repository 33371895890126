import { Event, Note } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { HOLIDAY_COMPANY_FIELD_ID, HOLIDAY_DATE_FIELD_ID, HOLIDAY_NAME_FIELD_ID } from "../constants/Constants";


export const holidayFields = {
    [HOLIDAY_NAME_FIELD_ID]: {
        id: HOLIDAY_NAME_FIELD_ID,
        name: HOLIDAY_NAME_FIELD_ID,
        type: "text",
        label: "Holiday Name",
        placeholder: "Enter Holiday Name",
        variant: "outlined",
        autoFocus: true,
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Note fontSize="small" />
                </InputAdornment>
            )
        }
    },


    [HOLIDAY_DATE_FIELD_ID]: {
        id: HOLIDAY_DATE_FIELD_ID,
        name: HOLIDAY_DATE_FIELD_ID,
        type: "date",
        label: 'Holiday Date ',
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Event fontSize="small" />
                </InputAdornment>
            )
        },
        // inputProps: { max: moment(new Date()).endOf('week').format('yyyy-MM-DD') }
    },
    [HOLIDAY_COMPANY_FIELD_ID]: {
        id: HOLIDAY_COMPANY_FIELD_ID,
        name: HOLIDAY_COMPANY_FIELD_ID,
        select: true,
        label: 'Holiday Date ',
        variant: "outlined",
        required: true,
        InputLabelProps: { shrink: true },
        InputProps:
        {
            startAdornment: (
                <InputAdornment position="start">
                    <Event fontSize="small" />
                </InputAdornment>
            )
        },
        // inputProps: { max: moment(new Date()).endOf('week').format('yyyy-MM-DD') }
    },

}