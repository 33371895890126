import React from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "../../../guards/PrivateRoute";
import Layout from "../../edvenswa.emportal.layout/Layout";
import { BIWEEKLY_MAPPING } from "../constant/Constants";


const BiWeeklyComponent = React.lazy(() => import("../pages/WeeklySprintReport"));

export const BIWEEKLY_ROUTES = [
  <Route
    path={BIWEEKLY_MAPPING}
    element={
      <PrivateRoute>
        <Layout
          component={BiWeeklyComponent}
          path={BIWEEKLY_MAPPING}
          title="WeeklySprint Report"
        ></Layout>
      </PrivateRoute>
    }
    key={"WEEKLYSPRINT_ROUTE"}
  ></Route>
];
