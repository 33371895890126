import { Tooltip } from '@mui/material';
import React from 'react';
import '../css/ratings.css';
import { useNavigate } from 'react-router';
const PastRatingsCustomCell = (props) => {
    const { ratings, type, astId, setStartDate, setEndDate, isAppraisalPage, project } = props;
    const navigate = useNavigate();

    const getColorClass = (rating) => {
        switch (rating) {
            case 1:
                return "green-ball";
            case 0:
                return "orange-ball";
            case -1:
                return "red-ball";
            default:
                return "";
        }
    };

    const handleClick = (event, startDate, endDate) => {
        event.preventDefault();
        if (isAppraisalPage) {
            setStartDate(startDate);
            setEndDate(endDate);
        }
        navigate("/home/ratings/UserAppraisalForm", {
            state: {
                startDate, endDate,
                astId: astId,
                project: project
            }
        })
    }

    const showRating = (rating) => {
        const truncatedScore = Math.floor(rating * 100) / 100;
        const formattedScore = truncatedScore.toFixed(2);
        return formattedScore;
    }

    return (
        <div className="ratings-container" style={{ marginTop: !type && '11px' }}>
            {ratings && ratings?.['binaryRatings'] && ratings?.['binaryRatings'].map((rating, index) => {
                const formatToDDMMYY = (dateString) => {
                    const date = new Date(dateString);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const year = String(date.getFullYear()).slice(-2);
                    return `${day}/${month}/${year}`;
                };

                const startDate = formatToDDMMYY(ratings?.["dates"][index]?.startDate);
                const endDate = formatToDDMMYY(ratings?.["dates"][index]?.endDate);

                return (
                    <Tooltip
                        key={index}
                        title={
                            <>
                                {`${startDate} - ${endDate}`}
                                <br />
                                {`Rating: ${showRating(ratings?.['ratings'][index])}`}
                            </>
                        }
                    >
                        <span
                            onClick={(e) =>
                                handleClick(
                                    e,
                                    ratings?.["dates"][index]?.startDate,
                                    ratings?.["dates"][index]?.endDate
                                )
                            }
                            className={`ball ${getColorClass(rating)}`}
                        ></span>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export default PastRatingsCustomCell;
