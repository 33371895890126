import { holidayFields } from "./Fields";

export const getHolidayFields = (keys = []) => {
    let fields = [];
    keys.forEach((key) => {
        const field = holidayFields[key];
        //avoid adding duplicate data
        if (!fields.includes(field)) {
            fields.push(field);
        }
    });
    return fields;
}