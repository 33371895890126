export const routes = [
    { id: "Users", path: "/home/users", text: "Can view and access Associate information" },
    { id: "Projects", path: "/home/projects", text: "Can view and access Project details" },
    { id: "Tasks", path: "/home/tasks", text: "Can view and access Task assignments" },
    { id: "Timesheets", path: "/home/timecard/timesheet", text: "Can view and access submitted Timesheets for approval" },
    { id: "Holidays", path: "/home/holidays", text: "Can view and access Holiday schedules" },
    { id: "Company", path: "/home/company", text: "Can view and access Company details" },
    { id: "Reports", path: "/home/reports", text: "Can view and access Reports" },
    { id: "Analytics", path: "/home/analytics", text: "Can view and access Analytics data" },
    { id: "Ratings", path: "/home/ratings", text: "Can view and access performance ratings" },
    { id: "Approvals", path: "/home/timecard", text: "Can view and access approval processes" },
    { id: "Add Associates", path: "/home/users", state: { isDrawerOpen: true }, text: "Can add new associate into the Org" },
    { id: "Add Projects", path: "/home/projects", state: { isDrawerOpen: true }, text: "Can add a new project record" },
    { id: "Add Company", path: "/home/company", state: { isDrawerOpen: true }, text: "Can add a new company record" },
    { id: "Add Holidays", path: "/home/holidays", state: { isDrawerOpen: true }, text: "Can add a new holiday into the schedule" }
];
