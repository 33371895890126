import InfoIcon from '@mui/icons-material/Info';

export const ASSOCIATE_ID_FIELD_ID = 'associateId';
export const ASSOCIATE_USERNAME_FIELD_ID = 'username';
export const ASSOCIATE_STATUS_FIELD_ID = 'status';
export const ASSOCIATE_ROLES_FIELD_ID = 'roles';
export const ASSOCIATE_FIRSTNAME_FIELD_ID = 'firstname';
export const ASSOCIATE_LASTNAME_FIELD_ID = 'lastname';
export const ASSOCIATE_DATE_OF_BIRTH_FIELD_ID = 'dateOfBirth';
export const ASSOCIATE_DATE_OF_JOINING_FIELD_ID = 'dateOfJoining';
export const ASSOCIATE_DESIGNATION_FIELD_ID = 'designation';
export const ASSOCIATE_EMAIL_FIELD_ID = 'email';
export const ASSOCIATE_MIDDLENAME_FIELD_ID = 'middlename';
export const ASSOCIATE_IMAGE_FIELD_ID = 'logo';
export const ASSOCIATE_MOBILE_FIELD_ID = 'mobile';
export const ASSOCIATE_COMPANY_FIELD_ID = 'tenant';

export const ASSET_TYPE_FIELD_ID = 'assetType';
export const ASSET_FIELD_ID = 'asset';
export const ASSET_CATEGORY_FIELD_ID = 'assetCategory';
export const ASSET_LATEST_CONDITION_FIELD_ID = 'latestCondition';

export const ASSOCIATE_TYPE_FIELD_ID = 'type';
export const ASSOCIATE_DEFAULT_IMAGE = "https://img.freepik.com/premium-vector/anonymous-user-circle-icon-vector-illustration-flat-style-with-long-shadow_520826-1931.jpg";

export const ASSOCIATE_PATH = "/home/users";
export const ASSOCIATE_DEATILS_PATH = "/home/users/details";
export const DESIGNATION_MAPPING = "/user/designation";
export const DIALOG_TYPE="Designation";
export const DIALOG_FIELD_NAME="designation"
export const ASSOCIATE_PROJECT_ICON = InfoIcon

