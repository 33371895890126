import { Grid, IconButton, Link, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GenericTable from "../../edvenswa.emportal.common/pages/GenericTable";
import { axiosInstance } from "../../../interceptors/AxiosInterceptor";
// import GenerateReport from "./GenerateReport";
import VisibilityIcon from '@mui/icons-material/Visibility';
import 'jspdf-autotable';
import moment from "moment";
import { utils, write } from 'xlsx';
import saveAs from "file-saver";
import jsPDF from 'jspdf';
import PastRatingsCustomCell from "../../edvenswa.emportal.reports/pages/PastRatingsCustomCell";

const ProjectManagerAppraisal = (props) => {
    const { onError, setLoad, user } = props;
    const navigate = useNavigate();
    const [users, setusers] = useState([]);
    const [filteredusers, setFilteredusers] = useState([]);
    const [username, setusername] = useState("");
    const [userTasks, setUserTasks] = useState([]);
    const [tenantId, setTenantId] = useState(user.tenantId);
    const [project, setProject] = useState("All")
    const [datefilter, setDateFilter] = useState("Last Bi-Weekly");
    const [page, setPage] = useState(1);
    const sortBy = "ALL"
    const pageSize = 5;
    const totalPages = 0;
    const [searchTerm, setSearchTerm] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const role = user.roles[0]
    const type = "Appraisals";
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [startDate, setStartDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).startOf('month').add(15, 'day').format('YYYY-MM-DD') : moment(new Date()).startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).format('DD') > 15 ? moment(new Date()).endOf('month').format('YYYY-MM-DD') : moment(new Date()).startOf('month').add(14, 'days').format('YYYY-MM-DD'));
    const [pastRatings, setPastRatings] = useState({});
    const [projectFromAppraisal, setProjectFromAppraisal] = useState("All");



    let arr = [];
    if ((moment(startDate).format('DD') === '01' || moment(startDate).format('DD') === '16') &&
        (moment(endDate).format('DD') === '15' || moment(endDate).format('DD') === moment(endDate).daysInMonth().toString())) {
        let count = 1
        for (
            let i = moment(startDate);
            moment(i).isSameOrBefore(endDate);
            i = moment(i).add(1, "day")
        ) {
            if (moment(i).format('DD') === '01' || moment(i).format('DD') === '15' || moment(i).format('DD') === '16' || moment(i).format('DD') === moment(i).daysInMonth().toString()) {
                count++;
                if (count % 2 == 0) {
                    arr.push(moment(i).format("DD/MMM"))
                }
                else {
                    arr.push(moment(i).format("DD/MMM/YYYY"))
                }
            }
        }
    }
    else {
        console.log('select dates correctly... startdate should start of month and enddate should be end of month ')
    }

    const CustomNameComponent = (row) => {
        return <Grid sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title="click to view">
                <IconButton onClick={() => {
                    const data = {
                        user: { username: row.data.username },
                        startDate: startDate,
                        endDate: endDate
                    }
                    axiosInstance.put("/user/getRatingsByUser", data).then((res) => {
                        if (res.data.length > 0) {
                            navigate("/home/ratings/UserScreenAppraisal", {
                                state: {
                                    userRatings: res.data, type: type, username: row.data.username
                                }
                            });
                            setLoad(false)
                        }
                        else {
                            const error = {
                                "message": `No Ratings Found for the ${row.data.firstname} ${row.data.lastname}`
                            };
                            setLoad(false)
                            onError(error);
                        }
                    }).catch(err => onError(err));
                }}>
                    <VisibilityIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="click to give ratings">
                <Link sx={{ cursor: 'pointer', }} underline="hover" onClick={() => {
                    // setLoad(true)
                    navigate("/home/ratings/UserAppraisalForm", {
                        state: {
                            fullname: `${row.data.firstname} ${row.data.lastname}`, astId: row.data.associateId, username: row.data.associateId, project: row.data.projectTitles,
                            startDate: startDate,
                            endDate: endDate

                        },
                    });
                }
                }
                > {row.data.firstname} {row.data.lastname}</Link >
            </Tooltip >
        </Grid >
    };

    const ScoreCell = (row) => {
        const score = row.data.score > 0
            ? row.data.score
            : pastRatings?.[row?.data?.associateId]?.score ?? 0;
        const rawScore = (score / 63) * 5;
        const truncatedScore = Math.floor(rawScore * 100) / 100;
        const formattedScore = truncatedScore.toFixed(2) > 0 ? truncatedScore.toFixed(2) : 0;

        return (
            <Grid sx={{ display: 'flex', mt: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography sx={{ textAlign: 'right', width: '100%' }}>
                    {isNaN(formattedScore) ? "--" : formattedScore}
                </Typography>
            </Grid>
        );
    };


    const handleDownloadReport = () => {
        axiosInstance.post(`/user/getAllassociatesforRatings?project=${projectFromAppraisal}&sortBy=${sortBy}&tenantId=${tenantId}`,
            { startDate, endDate }).then(res => {
                const filteredElements = res.data;
                if (filteredElements.length > 0) {
                    const pdfWidth = 210;
                    const pdfHeight = 250;
                    const pdf = new jsPDF({
                        orientation: "landscape",
                        unit: "mm",
                        format: [pdfWidth, pdfHeight],
                    });
                    const logoUrl = 'https://tse3.mm.bing.net/th?id=OIP.xrnfPsu-nIEVf0HzuyWt3AAAAA&pid=Api&P=0&h=180';
                    const centerX = pdf.internal.pageSize.width / 2;
                    const imageWidth = 20;
                    const imageHeight = 20;
                    const imageY = 2;
                    pdf.addImage(logoUrl, centerX - imageWidth / 2, imageY, imageWidth, imageHeight);

                    const formattedData = filteredElements.map((row) => {
                        return {
                            "Ast ID": row?.associateId ? row?.associateId : "",
                            "FullName": row?.firstname && row?.lastname ? `${row?.firstname} ${row?.lastname}` : "",
                            "Projects": row?.projectTitles ? row.projectTitles : "",
                            "Avg. Ratings": row?.ratings.toFixed(2),
                            // "userAppraisalsList": row?.userAppraisalsList?.length > 0
                            //     ? row?.userAppraisalsList.map((data) => {
                            //         return `StartDate: ${moment(data.startDate, 'YYYY,MM,DD').format("DD-MMM-YYYY")}, EndDate: ${moment(data.endDate, 'YYYY,MM,DD').format("DD-MMM-YYYY")}, Rating: ${data.ratingAvg}`;
                            //     }).join("\n\n")
                            //     : `StartDate: ${moment(startDate).format("DD-MMM-YYYY")}, EndDate: ${moment(endDate).format("DD-MMM-YYYY")}, Rating: 0`
                        };
                    });
                    const header = [
                        "Ast ID",
                        "FullName",
                        "Projects",
                        "Avg. Ratings",
                        // "User Appraisals"
                    ];

                    // const userData = formattedData.map((val) => header.map((header) => header !== "userAppraisalsList" ? val[header] : val[header]?.map((data) => Object.keys(data).map(key => data[key]))))
                    const userData = formattedData.map((val) => {
                        return [
                            val["Ast ID"],
                            val["FullName"],
                            val["Projects"],
                            val["Avg. Ratings"],
                            // val["userAppraisalsList"] // already formatted as a string
                        ];
                    });

                    const tableStartY = imageY + imageHeight + 20; //44

                    pdf.autoTable({
                        head: [header],
                        body: userData,
                        margin: { left: 8, right: 8, bottom: 10 },
                        startY: startDate && endDate ? 58 : 58,
                        rowPageBreak: 'avoid',
                        headStyles: { fillColor: "#008080", textColor: "#ffffff", fontStyle: "bold" },

                        didDrawPage: function (data) {
                            if (data.pageNumber === 1) {
                                pdf.setFont("Times New Roman, Times, serif");
                                pdf.text(`Dear Admin, Following is the report of All Users.`, 12, tableStartY - 6);
                                pdf.setFontSize(13);
                                pdf.text(`For the time period of ${moment(startDate).format('DD-MMM-yyyy')} to ${moment(endDate).format('DD-MMM-yyyy')} `, 12, 46);
                                // pdf.text((`Total Working Hours- ${Math.floor(totalDates.length * 8)}:00, Total Hours Logged- ${totalLoggedHours}, Total Leave Hours- ${totalLeaveHours},  Avg. Utilization- ${formattedPercentage}`), 12, startDate && endDate ? 54 : 46);
                            }
                        },
                        didParseCell: function (data) {
                            data.cell.styles.lineWidth = 0.1;
                            data.cell.styles.lineColor = [0, 0, 0];
                        },
                    });

                    pdf.save(`AllAssociateRatings.pdf`);
                }

            }).catch(err => onError(err));


    }

    const handleDownloadReportExcel = () => {
        ("Excel Report...");
        axiosInstance
            .post(
                `/user/getAllassociatesforRatings?project=${projectFromAppraisal}&sortBy=${sortBy}&tenantId=${tenantId}`,
                { startDate, endDate }
            )
            .then((res) => {
                const filteredElements = res.data;

                const fileName = `All Associates Ratings Report.xlsx`;

                if (filteredElements.length > 0) {

                    const formattedData = filteredElements.map((row) => {
                        return {
                            "Ast ID": row?.associateId ? row?.associateId : "",
                            "FullName": row.firstname && row.lastname ? `${row.firstname} ${row.lastname}` : "",
                            "Projects": row.projectTitles,
                            "Comments": '',
                            "Ratings": row?.ratings ? parseFloat(row?.ratings.toFixed(2)) : 0.00,
                            "userAppraislsList": row.userAppraisalsList ? row.userAppraisalsList : ''
                        };
                    });
                    let userArr = [];
                    let newObj = {}
                    for (let i = 0; i < arr.length; i++) {
                        if (i % 2 == 0) {
                            newObj = { ...newObj, [arr[i].concat(' - ').concat(arr[i + 1])]: 0 }
                        }
                    }
                    formattedData.map((data) => {
                        let userObj = {};
                        let comment = ''
                        if (data["userAppraislsList"].length !== 0) {
                            let changedNewObj = { ...newObj };
                            data["userAppraislsList"].map((appraisal) => {
                                comment = appraisal?.comments

                                Object.keys(newObj).map((key) => {
                                    if (key === moment(appraisal?.startDate, 'YYYY,MM,DD').format("DD/MMM").concat(' - ').concat(moment(appraisal?.endDate, 'YYYY,MM,DD').format("DD/MMM/YYYY"))) {
                                        changedNewObj[key] = parseFloat(appraisal?.ratings.toFixed(2))
                                    }
                                })
                            })
                            userObj = { ...data, ...changedNewObj, Comments: comment }
                        } else {
                            userObj = { ...data, ...newObj }
                        }
                        userArr.push(userObj);
                    })

                    const header = [
                        "Ast ID",
                        "FullName",
                        "Projects",
                        "Comments",
                        "Ratings",
                    ];
                    const newHeader = header.concat(Object.keys(newObj));

                    const userData = userArr.map((val) => newHeader.map((header) => val[header]))
                    // Create the worksheet and workbook
                    const worksheet = utils.aoa_to_sheet([newHeader, ...userData]);
                    const workbook = {
                        Sheets: { data: worksheet },
                        SheetNames: ["data"],
                    };

                    // Convert workbook to excel buffer
                    const excelBuffer = write(workbook, {
                        bookType: "xlsx",
                        type: "array",
                    });

                    // Create a Blob and trigger download
                    const fileType =
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                    const data = new Blob([excelBuffer], { type: fileType });
                    saveAs(data, fileName);
                }
            })
            .catch((err) => onError(err));
    }

    const PastRatingsCell = (row) => {
        const ratings = pastRatings?.[row?.data?.associateId] ?? [];
        return (
            <PastRatingsCustomCell
                ratings={ratings}
                astId={row?.data?.associateId}
                project={row?.data?.projectTitles}
            />
        );
    };
    // const PastRatingScoreCell = (row) => {
    //     const associateId = row?.data?.associateId;
    //     const score = pastRatings?.[associateId]?.score || 0;

    //     return (
    //         <Grid sx={{ display: 'flex', mt: 1, alignItems: 'center' }}>
    //             <Typography>{score}</Typography>
    //         </Grid>
    //     );
    // };

    const getRatingAge = (row) => {
        if (pastRatings?.[row?.data?.associateId]?.score === 0) {
            return <div style={{ color: 'gray' }}>No rating available</div>;
        }
        const ratingDate = pastRatings?.[row?.data?.associateId]?.dates?.[0]?.endDate;
        if (!ratingDate) {
            return <div style={{ color: 'gray' }}>No rating available</div>;
        }
        const rating = new Date(ratingDate);
        const currentDate = new Date();
        const timeDifference = currentDate - rating;
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const weeksDifference = Math.floor(daysDifference / 7);
        const color = weeksDifference > 2 ? 'gray' : 'black';
        if (daysDifference < 7) {
            return (
                <div style={{ color }}>
                    {`${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`}
                </div>
            );
        } else {
            return (
                <div style={{ color }}>
                    {`${weeksDifference} week${weeksDifference > 1 ? 's' : ''} ago`}
                </div>
            );
        }
    };

    const getRatingAgeCount = (row) => {
        if (pastRatings?.[row?.data?.associateId]?.score === 0) {
            return 0;
        }
        const ratingDate = pastRatings?.[row?.data?.associateId]?.dates?.[0]?.endDate;
        const rating = new Date(ratingDate);
        const currentDate = new Date();
        const timeDifference = currentDate - rating;
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const weeksDifference = Math.floor(daysDifference / 7);
        if (daysDifference < 7) {
            return daysDifference;
        } else {
            return weeksDifference;
        }
    }



    const colDefs = [
        {
            field: "associateId",
            headerName: "Ast ID",
            // width: 200,
            flex: 1
        },
        {
            field: "fullname",
            headerName: "Full Name",
            cellRenderer: CustomNameComponent,
            valueGetter: (params) => {
                return `${params.data.firstname} ${params.data.lastname}`;
            },
            // width: 200,
            flex: 1
        },
        {
            field: "projectTitles",
            headerName: "Project",
            // width: 200,
            flex: 1
        },
        {
            headerName: "Rating",
            cellRenderer: ScoreCell,
            // width: 200,
            flex: 1,
            valueGetter: (params) => {
                return params.data.score > 0 ? params.data.score
                    : pastRatings?.[params?.data?.associateId]?.score ?? 0;
            },
        },
        {
            headerName: "Performance Ratings",
            cellRenderer: PastRatingsCell,
            // width: 200,
            flex: 1
        },

        {
            headerName: "Time Since Rated",
            cellRenderer: getRatingAge,
            valueGetter: (params) => {
                return getRatingAgeCount(params);
            },
            flex: 1,
        }
    ];

    if (datefilter === "Last 3 months") {
        let newObj = {}
        for (let i = 0; i < arr.length; i++) {
            if (i % 2 == 0) {
                colDefs.push({ "field": arr[i].concat(' - ').concat(arr[i + 1]), "flex": 1 })
                newObj = { ...newObj, [arr[i].concat(' - ').concat(arr[i + 1])]: 0 }
            }
        }

    }



    const displayAdd = true;
    useEffect(() => {
        props.setState({});
        setLoading(true)
        let userArr = [];
        let newObj = {}
        for (let i = 0; i < arr.length; i++) {
            if (i % 2 == 0) {
                newObj = { ...newObj, [arr[i].concat(' - ').concat(arr[i + 1])]: 0 }
            }
        }
        if (searchTerm.length === 0) {
            axiosInstance.post(`/user/getAllassociatesforRatings?project=${projectFromAppraisal}&sortBy=${sortBy}&tenantId=${tenantId}`,
                { startDate: startDate, endDate: endDate }).then(res => {

                    res.data.map(data => {
                        let userObj = {};
                        let comment = ''
                        if (data.userAppraisalsList.length !== 0) {
                            let changedNewObj = { ...newObj };
                            data.userAppraisalsList.map((appraisal) => {
                                comment = appraisal?.comments

                                Object.keys(newObj).map((key) => {
                                    if (key === moment(appraisal?.startDate, 'YYYY,MM,DD').format("DD/MMM").concat(' - ').concat(moment(appraisal?.endDate, 'YYYY,MM,DD').format("DD/MMM/YYYY"))) {
                                        changedNewObj[key] = parseFloat(appraisal?.ratings.toFixed(2))
                                    }
                                })
                            })
                            userObj = { ...data, ...changedNewObj, Comments: comment }
                        } else {
                            userObj = { ...data, ...newObj }
                        }
                        userArr.push(userObj);

                    })
                    setusers(userArr)
                    setFilteredusers(userArr)
                    const astIds = res?.data.map(user => user?.associateId);
                    if (astIds.length > 0) {
                        axiosInstance
                            .put(`/user/get/past/ratings?endDate=${endDate}`, astIds)
                            .then(res => setPastRatings(res?.data))
                            .catch(err => console.log(err));
                    }
                    setLoading(false);
                })

        }
        else {
            const searchedUsers = users.filter((data) => (
                (data["firstname"].toLowerCase().includes(searchTerm)) ||
                (data["lastname"].toLowerCase().includes(searchTerm)) ||
                // (data["fullname"].toLowerCase().includes(searchTerm)) ||
                (`${data.associateId}`.toLowerCase().includes(searchTerm.toLowerCase()))))
            setFilteredusers(searchedUsers)
            setLoading(false)
        }

    }, [startDate, endDate, projectFromAppraisal]);


    return (
        <>
            <GenericTable
                type={type}
                setTenantId={setTenantId}
                Companies={props.Companies}
                setUsers={setusers}
                colDefs={colDefs}
                elements={users}
                filteredElements={filteredusers}
                setFilteredElements={setFilteredusers}
                setProjectFromAppraisal={setProjectFromAppraisal}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                page={page}
                setPage={setPage}
                setDateFilter={setDateFilter}
                setProject={setProject}
                totalPages={totalPages}
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                errorMessage={errorMessage}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setStartDateError={setStartDateError}
                setEndDateError={setEndDateError}
                loading={loading}
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                handleDownloadReport={handleDownloadReport}
                handleDownloadReportExcel={handleDownloadReportExcel}
                displayAdd={false}
                displayHeader={displayAdd}
            />

        </>
    );
};

export default ProjectManagerAppraisal;