
export const TASK_TITLE_FIELD_ID = 'taskName';
export const TASK_DESCRIPTION_FIELD_ID = 'description';
export const TASK_CATEGORY_FIELD_ID = 'taskCategory';
export const TASK_PROJECTS_FIELD_ID = 'project';
export const TASK_START_DATE_FIELD_ID = 'startDate';
export const TASK_END_DATE_FIELD_ID = 'endDate';
export const TASK_WORKEDHOURS_FIELD_ID = 'workedHours';
export const TIMESHEET_TASKS_FIELD_ID='taskId';
export const ASSOCIATE_FIELD_ID="associates";

export const REPORTS_PATH ="/home/reports";
export const DOWNLOAD_REPORTS_PATH ="/home/reports/download";
export const ALL_REPORTS_PATH ="/home/reports/view";