import React from "react";
import { Route } from "react-router-dom";

const SigninComponent = React.lazy(() => import("../pages/Signin"));
const PrivacyPolicyComponent = React.lazy(() => import("../pages/PrivacyPolicy"));

export const AUTH_ROUTES = [
  <Route index element={<SigninComponent />} key={"AUTH_ROUTE"} />,
  <Route path="/privacy-policies" element={<PrivacyPolicyComponent />} key={"PRIVACY_ROUTE"} />,
];
