import React, { useState } from 'react'
import ProjectManagerAppraisal from './ProjectManagerAppraisal'
import UserScreenAppraisal from './UserScreenAppraisal';
import AdminScreenAppraisal from './AdminScreenAppraisal';
import { Tabs, Tab, Typography, Box } from '@mui/material';
import GradeIcon from '@mui/icons-material/Grade';
import StarsIcon from '@mui/icons-material/Stars';

const ShowUsers = (props) => {
console.log(props);

const [tabValue,setATabValue]=useState(0);
    
const handleTabChange=(event,newValue)=>{
    setATabValue(newValue)
}


return (
    props.user.roles[0] === "Project Manager" || props.user.roles[0] === "Reporting Manager" ? (
        <>
            <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              "& .MuiTabs-indicator": {
                backgroundColor: "#029a9c",
              },
              "& .Mui-selected": {
                color: "#000000  !important",
              },
            }}
          >
                <Tab  label={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <GradeIcon fontSize="small" sx={{ color: "#437676" }} />
                  <Typography
                    style={{ color: "#016466", fontSize: '17px', fontFamily: "Lucida Grande" }}
                    sx={{ ml: 0.5 }}
                  >
                    Associate Ratings
                  </Typography>
                </Box>
              } />
                <Tab  label={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                 <StarsIcon fontSize="small" sx={{ color: "#437676" }} />
                  <Typography
                    style={{ color: "#016466", fontSize: '17px', fontFamily: "Lucida Grande" }}
                    sx={{ ml: 0.5 }}
                  >
                    My Ratings
                  </Typography>
                </Box>
              } />
            </Tabs>
            
            {
                tabValue === 0 ? (
                    <ProjectManagerAppraisal {...props} />
                ) : (
                    <UserScreenAppraisal {...props} />
                )
            }
        </>
    ) : props.user.roles[0] === "User"  ? (
        <UserScreenAppraisal {...props} />
    ) : (
        <AdminScreenAppraisal {...props} />
    ) 
);
}

export default ShowUsers
